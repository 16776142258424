import React from 'react'

import Layout from '../components/Layout'
import BoostHero from '../components/BoostHero'

const BoostPage = () => (
    <Layout
        title="Automate Real Estate Ads - Listing & Open House | Boost"
        description="Boost automates brokerage and agent digital advertising campaigns for listings, open houses, and more on Facebook, Instagram, and all across the web."
        keywords="boost by homespotter, automated real estate ads, real estate advertising automation, facebook real estate ads, advertising for real estate listings, advertising real estate on facebook, facebook real estate listings, facebook real estate listing ad automation, facebook listing ad automation, open house ad automation, real estate ad automation, marketing automation, advertising automation, listing ads, open house ads, facebook, instagram, real estate, listings, MLS"
        bodyClass="boost"
    >
        <BoostHero />
    </Layout>
)

export default BoostPage
