import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import WhoYouAreButtons from './WhoYouAreButtons'

const BoostHero = ({ data }) => {
    return (
        <div className="hero col-12">
            <div className="copy-content text-white span-7-mx">
                <h1>Meet your future clients using Boost</h1>
                <p>
                    Boost automates real estate digital advertising campaigns
                    for listings, open houses, and more on Facebook, Instagram,
                    and all across the web. These ads are visually compelling,
                    include smart copy, and are powered by the industry’s best
                    targeting to ensure the right audiences see the ads. Impress
                    your sellers and generate more leads, without lifting a
                    finger. Boost takes care of all of the important details for
                    you.
                </p>
                <div className="call-to-action">I need a solution for: </div>
                <WhoYouAreButtons path="boost" />
            </div>
            <GatsbyImage
                image={data.file.childImageSharp.gatsbyImageData}
                className="hero-illustration"
            />
        </div>
    )
}

const BoostHeroRender = props => (
    <StaticQuery
        query={graphql`
            {
                file(relativePath: { eq: "boostSolutionHero.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 502
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
            }
        `}
        render={data => <BoostHero data={data} {...props} />}
    />
)

export default BoostHeroRender
